import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ApiError,
  OpenAPI,
  QuartoItem,
  UtilizadorService,
  ViagemItem,
  ViagemSimple,
  ViagensGrupo,
} from '../slidein_api';
import { useAuth } from '../user-auth/SlideInAuthContext';
import FullPageLoader from '../layout/FullPageLoader';
import { Card } from 'primereact/card';
import { DataTable } from 'primereact/datatable';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { FilterMatchMode, PrimeIcons } from 'primereact/api';
import { MessageContext } from '../layout/context/MessageContext';
import { Image } from 'primereact/image';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Button } from 'primereact/button';
import axios from 'axios';
import FileSaver from 'file-saver';
import { InputSwitch } from 'primereact/inputswitch';
import { confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';

export function ChefeView() {
  const { user, logout } = useAuth();
  const { msgWarn } = useContext(MessageContext);
  const { grupoID } = useParams();
  const [viagem, setViagem] = useState<ViagemItem>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [grupo, setGrupo] = useState<ViagensGrupo>(undefined);
  const [viagens, setViagens] = useState<ViagemSimple[]>([]);
  const [livres, setLivres] = useState<QuartoItem[]>([]);
  const [uptrigger, setUpTrigger] = useState<number>(0);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [aloj, setAloj] = useState<string[]>([]);
  const { msgError } = useContext(MessageContext);
  const toast = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    p_atraso: { value: null, matchMode: FilterMatchMode.EQUALS },
    nao_presente: { value: null, matchMode: FilterMatchMode.EQUALS },
    pacote: { value: null, matchMode: FilterMatchMode.IN },
    quarto: { value: null, matchMode: FilterMatchMode.IN },
  });

  useEffect(() => {
    UtilizadorService.utilizadorQuartosList({ grupo: grupoID }).then(
      (value) => {
        setLivres(
          value
            .sort((a, b) => Number(a.esta_cheio) - Number(b.esta_cheio))
            .sort((a, b) => b.lotacao - a.lotacao)
        );
      },
      (reason: ApiError) => {
        if (reason.status === 401) {
          msgError('A sua sessão expirou, volte a fazer Login!');
        } else {
          msgError('O site encontra-se em manutenção! Tente Mais tarde. Obrigado.');
        }
        logout();
      }
    );
  }, [uptrigger]);

  useEffect(() => {
    if (!user) {
      return;
    }
    setLoading(true);
    const lol = user.viagens.find((vi) => {
      return vi.grupo.codigo === grupoID;
    });
    setViagem(lol);
    if (!lol?.chefe_de_grupo) {
      navigate('/');
    } else {
      UtilizadorService.utilizadorChefeViagensRetrieve({ codigo: grupoID }).then(
        (value) => {
          setGrupo(value);

          setViagens(
            value.viagens
              .filter((a) => !a.cancelada && !a.bloqueada_pagamentos)
              .sort((a, b) => {
                if (a.cancelada || a.bloqueada_pagamentos) {
                  return 1;
                }
                if (a.p_atraso) {
                  return -1;
                }
                if (b.p_atraso) {
                  return 1;
                }
                if (a.valor_devido === 0) {
                  return 1;
                }
                if (b.valor_devido === 0) {
                  return -1;
                }
                return 0;
              })
          );
          const temp = value.viagens.map((vi) => vi.pacote);
          setAloj(temp.filter((p, i) => temp.indexOf(p) === i));
          setLoading(false);
        },
        () => {
          navigate('/');
          msgWarn(
            'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
            true
          );
        }
      );
    }
  }, [user]);
  const quartoRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <MultiSelect
        value={options.value}
        options={livres}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel='nome'
        optionValue={'id'}
        placeholder=''
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };
  const pacoteRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <MultiSelect
        value={options.value}
        options={aloj}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder=''
        className='p-column-filter'
        maxSelectedLabels={1}
        style={{ minWidth: '14rem' }}
      />
    );
  };

  if (loading || !grupo) {
    return <FullPageLoader />;
  }
  const rowClass = (data: ViagemSimple) => {
    return {
      'text-green-600 font-bold':
        data.valor_devido === 0 && !(data.cancelada || data.bloqueada_pagamentos),
      'bg-red-400 text-black-alpha-70 font-bold ': data.p_atraso,
      'border-1 border-gray-400 cenasdisplay': true,
      'line-through': data.cancelada,
    };
  };

  const saveQuarto = (viagem: ViagemSimple, quarto: number) => {
    if (quarto === undefined) {
      quarto = null;
    }

    UtilizadorService.utilizadorComprarAtribuiQuartoUpdate({
      id: viagem.id,
      requestBody: {
        quarto: quarto,
      },
    }).then(
      () => {
        const mud = { ...viagem };
        mud['quarto'] = quarto;
        const tviagems = [...viagens];
        const i = viagens.findIndex((via) => via.id === viagem.id);
        tviagems[i] = mud;
        setViagens(tviagems);
      },
      () => {
        navigate('/');
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
      }
    );
  };

  const marcaNaoPresente = (viagem: ViagemSimple, naoPresente: boolean) => {
    UtilizadorService.utilizadorComprarMarcaNaoPresenteUpdate({
      id: viagem.id,
      requestBody: {
        nao_presente: naoPresente,
      },
    }).then(
      () => {
        const mud = { ...viagem };
        mud['nao_presente'] = naoPresente;
        const tviagems = [...viagens];
        const i = viagens.findIndex((via) => via.id === viagem.id);
        tviagems[i] = mud;
        setViagens(tviagems);
      },
      () => {
        navigate('/');
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
      }
    );
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className='flex justify-content-between flex-wrap'>
        <h3>Viajantes Grupo: {viagem.grupo.codigo}</h3>
        <Button
          outlined
          onClick={() => sinalPartida()}
          severity='help'
          icon={'pi pi-megaphone'}
          label='Comunicar a hora de partida e os viajantes que NÃO compareceram.'></Button>
        <Button
          outlined
          disabled={!grupo.mostrar_transporte}
          severity='success'
          icon={'pi pi-file-excel'}
          onClick={() => {
            const url = `${OpenAPI.BASE}/cliente/utilizador/chefe_viagens/${grupoID}/mapa_transportes`;
            axios
              .get(url, {
                responseType: 'blob',
                params: { codigo: grupoID },
              })
              .then((res) => {
                const blob = new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                });
                FileSaver.saveAs(blob, `transportes_${grupoID}.xlsx`);
                return res;
              });
          }}
          label={'Transportes.xlsx'}></Button>
        <Button
          outlined
          severity='success'
          disabled={!grupo.mostrar_quartos}
          icon={'pi pi-file-excel'}
          onClick={() => {
            const url = `${OpenAPI.BASE}/cliente/utilizador/chefe_viagens/${grupoID}/mapa_quartos`;
            axios
              .get(url, {
                responseType: 'blob',
                params: { codigo: grupoID },
              })
              .then((res) => {
                const blob = new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
                });
                FileSaver.saveAs(blob, `quartos_${grupoID}.xlsx`);
                return res;
              });
          }}
          label={'Quartos.xlsx'}></Button>
        <span className='p-input-icon-left w-full sm:w-28rem'>
          <i className='pi pi-search' />
          <InputText
            className={'w-full'}
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder='Procurar (Nome,email,telemóvel)'
          />
        </span>
      </div>
    );
  };
  const verifiedRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };
  const naoPresenteFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => {
          console.log(e);
          options.filterApplyCallback(e.value);
        }}
      />
    );
  };

  const accept = () => {
    UtilizadorService.utilizadorComprarSinalPartidaRetrieve({
      id: viagem.id,
    }).then(
      () => {
        toast.current.show({
          severity: 'info',
          summary: 'Confirmação de Partida',
          detail: 'Obrigado pela confirmação Boa Viagem!!!',
          life: 3000,
        });
      },
      () => {
        navigate('/');
        msgWarn(
          'A página encontra-se em manutenção, pedimos desculpa pelo incómodo, por favor tente mais tarde, obrigado.',
          true
        );
      }
    );
  };

  const sinalPartida = () => {
    confirmDialog({
      message: (
        <div>
          <p>Confirma que os seguintes viajantes NÃO COMPARECERAM!:</p>
          <ul>
            {viagens
              .filter((val) => val.nao_presente)
              .map((x) => (
                <li key={x.id}>{x.cliente}</li>
              ))}
          </ul>
          E que o Autocarro já partiu?`
        </div>
      ),
      position: 'center',
      header: 'Confirmação de partida',
      icon: 'pi pi-exclamation-triangle',
      accept,
    });
  };

  const header = renderHeader();
  // noinspection XmlDeprecatedElement
  return (
    <div className='flex flex-column justify-content-start gap-2'>
      <Toast ref={toast} />
      <Card>
        <div className={'flex flex-row gap-4 flex-wrap'}>
          <div className={'flex flex-shrink-1'}>
            <Image
              width={'100px'}
              src={grupo.campanha.marca.logo}
              imageStyle={{
                background: 'whitesmoke',
                padding: '6px',
                borderRadius: '5%',
              }}
            />
          </div>
          <div className={'flex flex-column flex-grow-1'}>
            <div className={'font-italic'}>
              <i className={PrimeIcons.PHONE + ' mx-1'} />
              {grupo.campanha.marca.telefone}
            </div>
            <div className={'font-italic'}>
              <i className={PrimeIcons.AT + ' mx-1'} />
              {grupo.campanha.marca.email}
            </div>
          </div>
          <Link
            className={'ml-4 text-2xl'}
            to={'/aminha_viagem/' + viagem.id}>
            <i className={PrimeIcons.LINK}></i>
            <b>Voltar a tua viagem</b>
          </Link>
        </div>
        <Divider></Divider>
        <div className={'text-3xl'}>{grupo.campanha.nome}</div>
        <div className={'text-2xl'}>
          A decorrer de {grupo.campanha.data_inicio_viagem} a {grupo.campanha.data_fim_viagem} (
          {grupo.campanha.nr_noites} noites)
        </div>
        <Divider></Divider>
        <div className={'text-xl'}>
          {grupo.instituicao}-{viagem.grupo.codigo}
        </div>
      </Card>

      <Card>
        <DataTable
          globalFilterFields={['cliente', 'email', 'representative.name', 'status']}
          header={header}
          filters={filters}
          filterDisplay='row'
          rowClassName={rowClass}
          responsiveLayout={'stack'}
          id={'vi_table'}
          value={viagens}
          breakpoint={'600px'}
          dataKey={'id'}
          loading={loading}
          showGridlines
          stripedRows={true}
          size='small'>
          <Column
            sortable
            field={'cliente'}
            header={'Nome'}
            body={(vi) =>
              vi.cancelada
                ? `${vi.cliente} (CANCELADA!)`
                : vi.bloqueada_pagamentos
                ? `${vi.cliente} (BLOQUEADA!)`
                : vi.cliente
            }></Column>
          <Column
            field={'email'}
            header={'Email'}></Column>
          <Column
            field={'telemovel'}
            header={'Telemóvel'}></Column>
          <Column
            sortable
            field={'valor_total'}
            header={'Total'}
            body={(vi) => vi.valor_total + '€'}
          />
          <Column
            sortable
            field={'valor_pago'}
            header={'Pago'}
            body={(vi) => vi.valor_pago + '€'}
          />
          <Column
            sortable
            field={'valor_devido'}
            header={'Por Pagar'}
            body={(vi) => vi.valor_devido + '€'}
          />
          <Column
            sortable
            align={'left'}
            dataType={'boolean'}
            className={'w-8rem'}
            filter
            filterElement={verifiedRowFilterTemplate}
            field={'p_atraso'}
            header={'Prestações Atrasadas'}
            body={(vi) => {
              return vi.p_atraso ? 'Sim' : 'Não';
            }}></Column>
          <Column
            filterField='pacote'
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filterElement={pacoteRowFilterTemplate}
            sortable
            filter
            header={'Alojamento'}
            field={'pacote'}></Column>

          <Column
            sortable
            align={'left'}
            dataType={'boolean'}
            className={'w-8rem'}
            filter
            filterElement={naoPresenteFilterTemplate}
            field={'nao_presente'}
            header={'Não Compareceu'}
            body={(viagem: ViagemSimple) => {
              if (viagem.cancelada) {
                return <b>CANCELADA!!</b>;
              }
              if (viagem.bloqueada_pagamentos) {
                return <b>BLOQUEADA</b>;
              }
              return (
                <InputSwitch
                  checked={viagem.nao_presente}
                  onChange={(e) => marcaNaoPresente(viagem, e.value)}
                />
              );
            }}></Column>

          <Column
            filterField='quarto'
            showFilterMenu={false}
            filterMenuStyle={{ width: '14rem' }}
            filterElement={quartoRowFilterTemplate}
            sortable
            field={'quarto'}
            filter
            header={'Quarto'}
            body={(viagem: ViagemSimple) => {
              if (viagem.cancelada) {
                return <b>CANCELADA!!</b>;
              }
              if (viagem.bloqueada_pagamentos) {
                return <b>BLOQUEADA</b>;
              }
              // if (!grupo.chefe_editavel) {
              //   return <b>{viagem.quarto}</b>;
              // }

              return (
                <Dropdown
                  value={viagem.quarto || undefined}
                  dataKey={'id'}
                  disabled={!grupo.chefe_editavel}
                  options={livres?.filter((qua) => qua.pacote === viagem.pacote_id)}
                  showClear={true}
                  emptyMessage={'Não existem quartos disponíveis.'}
                  optionLabel={'numero'}
                  valueTemplate={(quarto: QuartoItem | null) => {
                    if (quarto === null) {
                      return '--';
                    }
                    return <div>{quarto.nome}</div>;
                  }}
                  itemTemplate={(quarto: QuartoItem) => (
                    <div>
                      {quarto.nome} {quarto.livres}/{quarto.lotacao} Camas Livres
                    </div>
                  )}
                  optionDisabled={'esta_cheio'}
                  optionValue={'id'}
                  onChange={(e) => {
                    saveQuarto(viagem, e.value);
                  }}
                  onFocus={() => setUpTrigger(uptrigger + 1)}></Dropdown>
              );
            }}></Column>
        </DataTable>
      </Card>
    </div>
  );
}
